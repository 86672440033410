<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'App',
 
}
</script>

<style>
body {
  margin: 0;
  font-family: "Raleway",sans-serif;
}
</style>
