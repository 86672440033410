<template>
      <div class="content">
        <main>
            <h1>Введите код подтверждения</h1>
            <div class="row-block">
                <label>Введите код подтверждения, который вам передал коуч вместе со ссылкой на сеанс</label>
                <input type="text" placeholder="Введите код" v-model="code" required>
                <div class="error" v-if="error">{{error}}</div>
            </div>
            <div class="text-center">
                <button class="btn" v-on:click="login()">Продолжить</button>
            </div>
        </main>
      </div>
</template>
<script>
import http from '@/http-common';

    export default {
        data(){
            return {
                code:'',
                id: this.$route.params.id, //session id
                error: ''
            }
        },
        methods:{
            login(){
                this.error = '';
                if(this.code.length == 4){

                    http.post(`room/${this.id}/login`, {password: this.code})
                    .then(resp => {
                        console.log(resp);
                        localStorage.setItem('c_auth', this.id);
                        this.$router.push({ path: `/session/${this.id}` })
                    })
                    .catch(error => {
                        console.log(error);
                        this.error = error.response.data.message;
                    })
                }
                else {
                    this.error = 'Код должен состоять из 4 символов';
                }
            }
        }
    }
</script>
<style scoped>
    .content {
    /* min-height: calc(100vh - 80px); */
    display: flex;
    width: calc(100% - 60px);
    justify-content: space-between;
    padding: 20px 30px;
  }
  main {
    margin: 40px auto 0;
    box-sizing: border-box;
    width: 50%;
    max-width: 50%;
    background: #F3F8F6;
    border-radius: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  h1 {
        margin-top: 10px;
    }
    input {
        font-size: 14px !important;
        font-weight: 400 !important;
        display: block;
        margin-top: 10px;
        padding: 10px;
        min-height: 40px;
        border: 1px solid #888 !important;
        border-radius: 22px !important;
        box-sizing: border-box;
        width: 100%;
        color: #888 !important;
    }
    label {
        color: #888;
    font-size: 15px;
    margin-bottom: 0px;
    }
    .btn {
        text-decoration: none;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 15px 30px;
        border-radius: 22px;
        background-color: #555;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        margin: 30px auto;
        width: 0 auto;
        display: inline-block;
        /* margin-bottom: 10px; */
    }
    .text-center {
        text-align: center;
    }
</style>