<template>
    <div class="content">
        <aside>
    <div class="logo">Logo</div>
    <!-- <a href="#" v-if="!coachAuth" @click.prevent="TmpCoachAuth()">Авторизайтеся як коуч</a> -->
    <div style="font-size: 12px; margin-top: 30px; padding-right: 20px;">тут будет список запланированных сессий коуча</div>
   </aside>
        <main v-if="mode=='settings'">
            <h1>Выберите опции для начала сессии</h1>
            <div class="row">

                <div class="form-item">
                    
                    <label>Выберите методику </label>
                    <select v-model="methodSelected">
                        <option value="null">Выберите методику из списка </option>
                        <option v-for="method of methodics" :key="method.id" :value="method.id">{{method.name}}</option>
                    </select>
                </div>
                <div class="form-item">
                    <label>Выберите категорию пользователя</label>

                        <VueMultiselect 
                        v-model="chaptersSelectedValues"
                        :options="chapters" 
                        label="name"
                        :searchable="false" 
                        :close-on-select="false" 
                        :show-labels="false" 
                        :multiple="true"
                        track-by="name"
                        placeholder="Выберите категорию"
                        @update:model-value="getCards"
                        ></VueMultiselect>
                </div>
            </div>
            <div class="row">
                <div class="form-item">
                    <label>Введите имя клиента</label>
                    <input type="text" placeholder="Введите имя вашего клиента" v-model="clientName">
                </div>
            </div>
            <div class="form-item">
                <label>Выберите колоду для работы</label>
                <div class="cards" >
                    <a href="#" class="item" v-for="(item,index) of  cards" :key="index" v-on:click="selectCard(item)" :class="{bg: !item}" >
                        <img :src="baseUrl+item.image" :class="{selected: item.selected}" v-if="!!item && item.image">
                        <div class="title" v-if="!!item && item.name">{{item.name}}</div>
                    </a>
                </div>
            </div>
            <div class="text-center">
                <button class="btn" v-on:click.prevent="createRoom()">Далее</button>
            </div>
        </main>
        <main v-if="mode=='result'">
            <h1>Сеанс с клиентом {{clientName}}</h1>
            <div class="row-block">
                    <label>Ссылка для проведения сеанса</label>
                    <div class="result" v-if="result.slug">{{result.slug}}</div>
            </div>
            <div class="row-block">
                <label>Код для подтверждения</label>
                <div class="result" v-if="result.password">{{result.password}}</div>
            </div>
                <div class="text-center">
                <button class="btn" v-on:click="copyInvite()" >Скопировать приглашение клиенту</button>
            </div>
            

        </main>
        <aside>
    <a href="#" class="time" @click="Logout">Выйти</a>
   </aside>
        <!-- <router-link to="/session" class="btn">Начать сессию</router-link>/ -->
    </div>
</template>
<script>
import http from "../http-common";
import VueMultiselect from 'vue-multiselect'
import { copyText } from 'vue3-clipboard'
// import { ref } from 'vue'
    export default {
        components: {
            VueMultiselect,
        },
      data(){
      return {
        mode: 'settings', //вид отображения экрана
        cards: Array(6),
        methodics:[],
        chapters: [],
        methodSelected: null,
        chaptersSelected: [],
        chaptersSelectedValues:[],
        clientName: '',
        baseUrl: 'https://idev-test.com',
        preferences: {},
        user_id : 1,
        cardDeck: '',
        result: {},
        coachAuth: localStorage.a_auth,
      }
    },
    created(){
        localStorage.removeItem('c_auth');
    },
    mounted(){
        http.get('methods')
        .then(response => {
            this.methodics = response.data.data;
        });
        http.get('chapters')
        .then(response => {
            this.chapters = response.data.data;
            this.chapters.forEach(item => {
                item.valueProp = item.id;
                item.label = item.name;
                item.disabled = false;
            })
        });
    },
    computed:{
        valueIds() {
      return this.chaptersSelectedValues.map(v => v.id);
    },
    },
    methods: {
        selectCard(item){
            console.log(item);
            this.cards.forEach(item=>{
                item.selected = false;
            })
            item.selected = true;
            this.cardDeck = item.id;
        },
        getCards(){
            
            console.log(this.chaptersSelectedValues)
            let ids = []
            this.chaptersSelectedValues.forEach(item => {
                ids.push(item.id);
            })
            if(ids.length){

                console.log(ids)
                http.get('card_decks', {params: {
                    'chapters':ids
                }})
                .then(response =>{
                    console.log(response)
                    this.cards = response.data.data;
                })
            }
            else {
                this.cards = Array(6);
            }
        },
        createRoom(){
            let preferences = {};
            preferences.name = this.clientName;
            preferences.method = this.methodSelected;
            preferences.chapters = this.chaptersSelectedValues.map(item => {return item.id})
            preferences.cardDeck = this.cardDeck;
            console.log(preferences);
            http.post('room', {user_id: this.user_id, preferences: preferences})
            .then(resp => {
                console.log(resp);
                this.mode = 'result';
                this.result = resp.data;
                console.log(this.result)

            })
            .catch(error=>{
                console.log(error);
            })

        },
        copyInvite(){
            // const container = ref(null)
            let message = 'Для того, чтобы начать встречу c коучем, перейдите по ссылке https://idev-test.com/session/'+this.result.slug+' и введите код подтверждения: '+this.result.password;
            copyText(message, undefined,(error, event) => {
          if (error) {
            alert('Can not copy')
            console.log(error)
          } else {
            alert('Copied')
            console.log(event)
            this.$router.push({ path: `/session/${this.result.slug}` })
          }
        })
        
        },
        TmpCoachAuth(){
            localStorage.setItem('a_auth',this.user_id.toString());
            this.coachAuth = true;
        },
        Logout(){
            localStorage.removeItem('a_auth');
            this.$router.push({ path: `/auth` })
        }
    }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style >
  .content {
    min-height: calc(100vh - 80px);
    display: flex;
    width: calc(100% - 60px);
    justify-content: space-between;
    padding: 20px 30px;
  }
  .time {
    display: block;
    text-decoration: none;
    color: #000;
    padding-left: 30px;
    margin-top: 90px;
    font-weight: 600;
  }
  main {
    margin: 40px auto 0;
    box-sizing: border-box;
    width: 70%;
    max-width: 70%;
    background: #F3F8F6;
    border-radius: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
    .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .row .form-item {
        width: 48%;
    }

    h1 {
        margin-top: 10px;
    }
    .form-item {
        margin-bottom: 30px;
    }
    .logo {
    margin-top: 80px;
    font-weight: 600;
    font-size: 30px;
  }
  aside {
    width: 15%;
    min-width: 15%;
    max-width: 15%;
  }
    select, input, .multiselect__tags {
        font-size: 14px !important;
        font-weight: 400 !important;
        display: block;
        margin-top: 10px;
        padding: 10px;
        min-height: 40px;
        border: 1px solid #888 !important;
        border-radius: 22px !important;
        box-sizing: border-box;
        width: 100%;
        color: #888 !important;
    }
    .result {
        background: #fff;
        margin-top: 10px;
        display: block;
        width: 50%;
        padding: 10px;
        border-radius: 22px !important;
        color: #888 !important;
        font-size: 20px;
        border: 1px solid #888 !important;
        margin-bottom: 30px;
    }
    .multiselect__tags{
        padding-bottom: 0 !important;
    }
    label {
        color: #888;
    font-size: 15px;
    margin-bottom: 0px;
    }
   
    .btn {
        text-decoration: none;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 15px 30px;
        border-radius: 22px;
        background-color: #555;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        margin: 10px auto;
        width: 0 auto;
        display: inline-block;
        /* margin-bottom: 10px; */
    }
    .cards {
        margin-top: 30px;
        display: flex;
        /* overflow-y: auto; */
        
        width: 100%;
        white-space: nowrap;
    }
    .cards .item {
        width: 120px;
        min-height: 180px;
        height: 100%;
        display: inline-block;
        margin-right: 30px;
        margin: 0 10px;
        text-decoration: none;
        align-items: flex-start;
    
    }
    .cards .item.bg {
        background-color: #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 30px;
    } 
    
    .cards .item:first-of-type{
        margin-left: 0;
    }
    .cards .item:last-of-type{
        margin-right: 0;
    }
    .cards img {
        height: 180px;
        width: 120px;
        display: block;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 30px;
    position: relative;
    border: 3px solid transparent !important;
    }
    .cards  .selected {
        border: 3px solid #FCB024 !important;
        transition: border 0.25s ease-out;
    }
    .cards .title {
        font-size:  14px;
        font-weight: 500;
        margin-top: 5px;
        text-align: center;
        color: #888;
        font-size: 15px;
        max-width: 120px;
        white-space: pre-wrap;
    }
    .text-center {
        text-align: center;
    }
    
</style>