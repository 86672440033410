<template>
  <div class="content">
   <!-- <aside>
    <div class="logo">Logo</div>
   </aside> -->
   <main>
     <h1>Привет<span v-if="name">, {{name}} 👋</span></h1>
     <!-- <h2>Room : {{room}}</h2> -->
     
     <p class="descr">Листай колоду и выбирай карты</p>
     <div class="slider-container">
      <a href="#" class="slider-prev" v-on:click.prevent="Prev()" :class="{disabled:disablePrev}">
        <img src="../assets/arrow.svg">
      </a>
      <div class="slider">
        <div class="slider-row" :style="{'transform': 'translate3d('+transitionCount+'%,0,0)'}">
          <a href="#" class="card" v-on:click.prevent="SelectCard(item)" v-for="(item) of cards" :key="item.id" :class="{disabled:item.disabled}">
          <img :src="item.url">
        </a>
        </div>
      </div>
      <a href="#" class="slider-next" v-on:click.prevent="Next()" :class="{disabled:disableNext}">
        <img src="../assets/arrow.svg">
      </a>
     </div>
     <div class="cards cards-selected">
      <a href="#" class="card" v-on:click="removeSelected(item,i)" v-for="(item,i) in selectedCards" :key="i">
        <img :src="item" v-if="item">
      </a>

     
     </div>
   </main>
   <aside>
    <!-- <div class="time">Время сессии: 15:47</div> -->
    <div v-if="isCoach" style="margin-top: 20px; padding-left: 30px;">
      <iframe width="100%" height="400" :src="videoUrlCoach+'?skipMediaPermissionPrompt&precallReview=off'" allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>
    </div>
    <div v-if="isUser" style="margin-top: 20px; padding-left: 30px;">
      <iframe width="100%" height="400" :src="videoUrlUser+'?skipMediaPermissionPrompt&precallReview=off'" allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>
    </div>
    <div class="time" v-if="connections" style="margin-top:20px">Участников: {{connections}}</div>
   </aside>
  </div>
</template>

<script>
import httpCommon from '@/http-common';


// import VueSocketIO from 'vue-3-socket.io'
// import SocketIO from 'socket.io-client'
// import socket from 'this.$socket.io';

  export default {
    data(){
      return {
        isCoach: localStorage.a_auth,
        isUser: localStorage.c_auth,
        videoUrlUser:'',
        videoUrlCoach:'',
        transitionCount : 0,
        disablePrev : true,
        page: 0,
        pageMax:1,
        disableNext: false,
        selectedCards: Array(5),
        selectedCardIndex: 0,
        preferences: {},
        userName:'',
        cards:[],
        cardsCopy:[],
        connections: 0,
        room: this.$route.params.id,
        state : {
          page: 0,
          pageMax: 1,
          cards: Array(5),
          selectedCardIndex: 0,
        }
      }
    },   
    mounted(){
      this.sockets.subscribe("connect", () => {
            console.log("connect");
            this.connections = 1;
            this.$socket.emit("requestForState", {
              room: this.room
            });
          });

          this.sockets.subscribe("connect_error", () => {
            console.log("connect_error");
            setTimeout(() => {
              this.$socket.connect();
            }, 1000);
          });

          this.sockets.subscribe("disconnect", (reason) => {
            console.log(`disconnect: ${reason}`);
            this.connections = '??';
            if (reason === "io server disconnect") {
              // the disconnection was initiated by the server, you need to reconnect manually
              this.$socket.connect();
            }
            // else the socket will automatically try to reconnect
          });
        this.sockets.subscribe("reject", (reason) => {
            console.log(`reject: ${reason}`);
          });

          this.sockets.subscribe("connections", (data) => {
            console.log(`connections: ${data}`);
            this.connections = data;
          });
          
          this.sockets.subscribe("requestForState", (data) => {
            console.log(data);
            this.sendState();
            
          });
          // syncronization state arrived
          this.sockets.subscribe("state", (data) => {
            console.log(data)
            this.state = data.state;
            this.connections = data.connections;
            // сравнить карты и отобразить новую, если нужно
            // переснуть слайдер, если нужно
            
            this.selectedCards = this.state.cards
            this.selectedCardIndex = this.state.selectedCardIndex
            this.page = this.state.page;
            this.pageMax = this.state.pageMax;
            setTimeout(()=>{
              this.checkSliderCards();
              this.sliderToPage();
            })
          });

    },
    created(){
        this.getCards();
        this.$socket.emit('requestForState',{room: this.room})
      
    },
    computed: {},
    methods : {
      sendState(){
        this.state.page = this.page
        this.state.pageMax = this.pageMax
        this.state.cards = this.selectedCards
        this.state.selectedCardIndex = this.selectedCardIndex;
        console.log(this.state)
        console.log(this.selectedCards)
        this.$socket.emit("state", {
          state: this.state,
          room: this.room
        });
      },
      Prev(){
        console.log(this.page);
        console.log(this.pageMax);
        if(this.page > 0){
          this.page--;
          this.sliderToPage();
          console.log(this.page);
          this.sendState();
        // this.state.page = this.page
        // this.state.pageMax = this.pageMax
        // this.state.cards = this.selectedCards;
        // this.state.selectedCardIndex = this.selectedCardIndex;


        // this.$socket.emit("state", {
        //       state: this.state,
        //       room: this.room,
        //     });
        }
        // this.disableNext = false;
        // if(this.transitionCount !== 0 ){
        //   this.page --;
        //   this.transitionCount = this.transitionCount *1 + 20;
        //   if(this.transitionCount == 0){
        //     this.disablePrev = true;
        //   }
        // }
        // else {
        //   this.disablePrev = true;
        // }
        
        
        
      },
      Next(){
        console.log(this.page);
        console.log(this.pageMax);
        if(this.page < this.pageMax){
          this.page ++;
          console.log(this.page);
        this.sliderToPage();
        this.sendState();
        // this.state.page = this.page
        // this.state.pageMax = this.pageMax
        // this.state.cards = this.selectedCards;
        // this.state.selectedCardIndex = this.selectedCardIndex;
        
        // this.$socket.emit("state", {
        //       state: this.state,
        //       room: this.room,
        //     });
        }
        // console.log(this.transitionCount)
        // console.log(this.transitionMax)
        // this.disablePrev = false;
        // if(this.transitionCount !== this.transitionMax*(-1)){
        //   this.page++;
        //   this.transitionCount -= 20;
        //   console.log(this.transitionCount)
        //   if(this.transitionCount == this.transitionMax*(-1)){
        //     this.disableNext = true;
        //   }
        // }
        // else {
        //   this.disableNext = true;
        // }
      
      },
      checkSliderCards(){
        console.log('CHECK SLIDER CARDS')
        this.cards.forEach(card => {
          card.disabled = false;
          this.selectedCards.forEach(item => {
            if(card.url == item){
              card.disabled = true;
            }
          })
        })
      },
      removeSelected(item,i){
        console.log(i);
        if(item){

          this.selectedCards.splice(i,1);
          this.selectedCards.push(null);
          if(this.selectedCardIndex > 1){
            this.selectedCardIndex --;
          }
          else this.selectedCardIndex =0;
          this.sendState();
          // this.state.page = this.page
          // this.state.pageMax = this.pageMax
          // this.state.cards = this.selectedCards;
          // this.state.selectedCardIndex = this.selectedCardIndex;
          
          // this.$socket.emit("state", {
            //       state: this.state,
            //       room: this.room,
            //     });
            setTimeout(()=>{
              this.checkSliderCards();
            })
          }
        
      },
      SelectCard(item){
        item.disabled = true;
        if(this.selectedCardIndex < 5){
          this.selectedCards[this.selectedCardIndex] = event.target.src;
          this.selectedCardIndex ++;
          console.log(this.selectedCardIndex)
        
        //change state
        this.sendState();
        setTimeout(()=>{
          this.checkSliderCards();
        })
      }
        
      },
      getCards(){
        httpCommon.get('/room/'+this.$route.params.id)
        .then(resp => {
          this.preferences = resp.data;
          this.name = resp.data.preferences.name;
          this.cards = resp.data.cards;
          // this.cardsCopy = Object.assign({},resp.data.cards);
          this.pageMax = (this.cards.length);
          this.page = 0;
          this.videoUrlCoach = resp.data.hostRoomUrl;
          this.videoUrlUser = resp.data.viewerRoomUrl;
          // this.transitionMax = (this.cards.length - 5)*20;

          //change state
          // this.sendState();
        // this.state.page = this.page;
        // this.state.pageMax = this.pageMax
        // this.state.cards = this.selectedCards;
        // this.state.selectedCardIndex = this.selectedCardIndex;
        // // this.state.sliderState = {'id': item.id, 'action': 'disabled'};
        // this.$socket.emit("state", {
        //       state: this.state,
        //       room: this.room,
        //     });
        setTimeout(()=>{
          this.checkSliderCards();
        })
      
        })
      },
      sliderToPage(){
        if(this.page == 0){
          this.disablePrev = true;
          this.disableNext = false;
        }
        if(this.page > 0 && this.page < this.pageMax){
          this.disablePrev = false;
          this.disableNext = false;
        }
        if(this.page == this.pageMax){
          this.disableNext = true;
          this.disablePrev = false;
        }
        if(this.page < this.pageMax){
          this.transitionCount = (this.page*20)*(-1);
        }
        console.log(this.transitionCount);
        // if(this.transitionCount == this.transitionMax*(-1)){
        //     this.disableNext = true;
        // }
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  iframe {
    border: none !important;
    border-radius: 30px !important;
    margin-top: 20px !important;
  }
  .slider-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .slider-next, .slider-prev {
    width: 30px;
    align-items: center;
    display: flex;
    position: relative;
    z-index: 100;
    /* top: calc(50% -20px); */
  }
  .slider-next {
    justify-content: flex-end;
    position: relative;
    
  }
  .slider-next::after{
    position: absolute;
    height: 100%;
    content: '';
    display: inline-block;
    width: 80px;
    left: 0px;
    background: #F3F8F6;
    /* background: -moz-linear-gradient(right, rgba(255,255,255,1) 0%, #F3F8F6 20%); */
/* background: -webkit-linear-gradient(right, rgba(255,255,255,1) 0%, #F3F8F6 20%); */
/* background: linear-gradient(to right, rgba(255,255,255,1) 0%, #F3F8F6 20%, #F3F8F6 100%); */
  }
 
  .slider-next.disabled, .slider-prev.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  /* .six, .card:nth-of-type(5) {
    box-shadow: none;
  } */
  .slider-next img, .slider-prev img {
    height: 40px;
  }
  .slider-next img {
    transform: rotate(180deg);
    z-index: 100;
  }
  .slider {
    display: flex;
    width: calc(100% - 60px);
    /* overflow-x: hidden; */
  }
  .slider-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 30px 0px 30px 0;
    height: 110%;
    width: 100%;
    /* transition: transform 0.25s cubic-bezier(.76,.31,.95,.62); */
    /* transition: transform 0.35s cubic-bezier(0.445, 0.050, 0.550, 0.950); плавно, как обычный слайдер */
    transition: transform 500ms cubic-bezier(0,2.01,1,.86);
    /* transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1); */
  }

  .slider-row.left {
    justify-content: flex-start !important;
  }

  .content {
    height: calc(100vh - 80px);
    display: flex;
    width: calc(100% - 40px);
    justify-content: space-between;
    padding: 20px 30px;
  }
  main {
    margin-top: 40px;
    width: 70%;
    max-width: 70%;
    background: #F3F8F6;
    border-radius: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  h1 {
    /* padding-left: 10px; */
    margin: 10px 0 0;
    font-size: 28px;
  }
  .descr {
    /* padding-left: 10px; */
    color: #888;
    font-size: 15px;
    margin-bottom: 0px;
  }
  .cards {
    display: flex;
    /* justify-content: space-between; */
    position: relative;
    /* width: calc(100% - 10px); */
    /* padding: 30px; */
    /* box-sizing: border-box; */
    /* overflow: hidden; */
  }

 
  .card {
    margin: 0 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 30px;
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.3s ease-out 0.3s;;
    
  }
  .card.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .card:first-of-type{
    margin-left: 0;
  }
  .card:last-of-type{
    margin-right: 0;
  }
  .card img {
    height: 100%;
  }

  .cards-selected {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    width: calc(100% - 60px);
    margin-left: 30px;
    /* justify-content: center; */
    /* justify-content: center; */
    /* overflow: hidden; */
  }
  .card {
    width: calc(20% - 20px);
    min-width: calc(20% - 20px);
    height: 230px;
  }
  
  .card img {
    width: 100%;
  }
  .cards-selected  .card {
    background: #fff;
   
  }
  .logo {
    margin-top: 80px;
    font-weight: 600;
    font-size: 30px;
  }
  aside {
    width: 30%;
    min-width: 30%;
    max-width: 30%;
  }
  .time {
    padding-left: 30px;
    margin-top: 90px;
    font-weight: 600;
  }
  .card:first-of-type{
    margin-left: 10px;
  }

</style>
