import {createApp } from 'vue'
import App from './App.vue'
import router from './router' 
import Vue3TouchEvents from "vue3-touch-events";
import VueClipboard from 'vue3-clipboard'
import '@/assets/main.scss';
import VueSocketIO from 'vue-3-socket.io'
const app = createApp(App);
app.use(router)
   .use(Vue3TouchEvents)
   .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(new VueSocketIO({
    debug: true,
    connection: 'https://idev-test.com',
    withCredentials: true,
    // options: {path: '/session/'}
}))
  .mount('#app')
