import { createWebHistory, createRouter } from "vue-router";
import SettingsSession from '../components/SettingsSession.vue';
import GameField from '../components/GameField.vue';
import ClientLogin from '../components/ClientLogin.vue';
import CoachLogin from '../components/CoachLogin.vue';

const routes = [
  {
    path: "/",
    name: "SettingsSession",
    component: SettingsSession,
    beforeEnter: (to) => {
      console.log(to)
      // let code = localStorage.getItem('c_auth');
      if(!localStorage.a_auth){
        return `/auth`;
      }
    },
  },
  {
    path: "/session/:id",
    name: "GameField",
    component: GameField,
    beforeEnter: (to) => {
      console.log(to)
      let id ;
      if(to.params.id){
        id = to.params.id;
      }
      // let code = localStorage.getItem('c_auth');
      if(localStorage.c_auth != to.params.id && !localStorage.a_auth){
        return `/login/${id}`;
      }
    },
  },
  {
    path: "/login/:id",
    name: "ClientLogin",
    component: ClientLogin,
  },
  {
    path: "/auth",
    name: "CoachLogin",
    component: CoachLogin,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;